import React from 'react';
import { graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import Hero from 'components/Hero';
import { resolveSlice } from 'utils/slice-resolver.js';

import css from './project.module.scss';
import shared from 'styles/shared.module.scss';

const Project = ({ data: { prismic } }) => {
  const content = prismic.allProjects.edges[0];
  if (!content) return null;

  const project = content.node;
  const image = project.hero_imageSharp;
  const slices = project.body || [];

  const hasIntro = project.show_intro !== false ? true : false;

  return (
    <>
      <Hero
        image={image ? image.childImageSharp.fluid : null}
        title={project.hero_title}
        subtitle={project.hero_subtitle}
        description={project.hero_description}
      />
      {hasIntro && (
        <section className={`${css.introSection} ${shared.maxwidth}`}>
          <h4 className={shared.sectionType}>{project.intro_label}</h4>
          <h2 className={shared.sectionTitle}>
            {RichText.asText(project.intro_title)}
          </h2>
          <div
            className={`${shared.sectionDescription} ${css.introDescription}`}
          >
            <RichText render={project.intro_description} />
          </div>
        </section>
      )}
      {slices.map(resolveSlice)}
    </>
  );
};

export const pageQuery = graphql`
  query ProjectById($uid: String) {
    prismic {
      allProjects(uid: $uid) {
        edges {
          node {
            name
            intro_label
            intro_title
            intro_description
            show_intro
            hero_title
            hero_subtitle
            hero_logo
            hero_image
            hero_imageSharp {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            hero_description
            _meta {
              uid
              type
            }
            body {
              ... on PRISMIC_ProjectBodyContent_section {
                primary {
                  title
                  type
                }
                fields {
                  main
                  icon
                  content_text
                  image
                  imageSharp {
                    childImageSharp {
                      fluid(quality: 90, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                type
              }
              ... on PRISMIC_ProjectBodyPartners_section {
                type
                label
                primary {
                  description
                  title
                }
                fields {
                  logo
                }
              }
              ... on PRISMIC_ProjectBodyRegistersection {
                type
                label
                fields {
                  form_id
                  label
                }
                register_primary: primary {
                  description
                  title
                  form_id
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Project;
